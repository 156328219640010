/* Base16 Atelier Estuary Light - Theme */
/* by Bram de Haan (http://atelierbram.github.io/syntax-highlighting/atelier-schemes/estuary) */
/* Original Base16 color scheme by Chris Kempson (https://github.com/chriskempson/base16) */

/* Atelier-Estuary Comment */
.hljs-comment,
.hljs-quote {
  color: #b5a5ba;
}

/* Atelier-Estuary Red */
.hljs-variable,
.hljs-template-variable,
.hljs-attribute,
.hljs-tag,
.hljs-name,
.hljs-regexp,
.hljs-link,
.hljs-name,
.hljs-selector-id,
.hljs-selector-class {
  color: #ba6236;
}

/* Atelier-Estuary Orange */
.hljs-number,
.hljs-meta,
.hljs-built_in,
.hljs-builtin-name,
.hljs-literal,
.hljs-type,
.hljs-params {
  color: #ae7313;
}

/* Atelier-Estuary Green */
.hljs-string,
.hljs-symbol,
.hljs-bullet {
  color: #7d9726;
}

/* Atelier-Estuary Blue */
.hljs-title,
.hljs-section {
  color: #36a166;
}

/* Atelier-Estuary Purple */
.hljs-keyword,
.hljs-selector-tag {
  color: #5f9182;
}

.hljs-deletion,
.hljs-addition {
  color: #22221b;
  display: inline-block;
  width: 100%;
}

.hljs-deletion {
  background-color: #ba6236;
}

.hljs-addition {
  background-color: #7d9726;
}

.hljs {
  display: block;
  overflow-x: auto;
  background: #f4f3ec;
  color: #5f5e4e;
  padding: 0.5em;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: bold;
}
